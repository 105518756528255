import React from 'react';

class NoMatch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }
    render() {
        return (
            <div className="App">
                404 - Not found
            </div>
        );
    }
}

export default NoMatch;