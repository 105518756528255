import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import './main.min.css';
import Cookies from 'universal-cookie';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Main from './components/Main';
import NoMatch from './components/NoMatch';
import { IsValueUndefined, ClearCookies, IsRighToLeftLanguage } from './Utility';
import { StripeProvider } from 'react-stripe-elements';
import StringTranslator from './LanguageTranslator';

const Activate = lazy(() => import('./components/Devices/Activate'));
const Devices = lazy(() => import('./components/Devices/Devices'));
const Firsttime = lazy(() => import('./components/Firsttime'));
const Settings = lazy(() => import('./components/Settings/Settings'));
const Orders = lazy(() => import('./components/Orders'));
const Events = lazy(() => import('./components/Events/Events'));
const Licenses = lazy(() => import('./components/Licenses/Licenses'));
const Admin = lazy(() => import('./components/Admin/Admin'));
const Unsubscribe = lazy(() => import('./components/Unsubscribe'));

export default class App extends React.Component {
    constructor(props) {
        super(props);

        console.debug("Initialize App");

        const cookies = new Cookies();
        const year = (new Date()).getFullYear();
        cookies.remove("Activation");
        let userData = cookies.get('UserData');
        if (userData === undefined) {
            userData = null;
        }

        let apiUrl = cookies.get('ApiUrl');
        if (apiUrl === undefined) {
            apiUrl = null;
        }

        let bearer = cookies.get('Bearer');
        if (IsValueUndefined(bearer)) {
            bearer = null;
        }

        let activation = cookies.get('DeviceActivation');
        if (IsValueUndefined(activation)) {
            activation = null;
        }

        let isAdminPage = cookies.get("AdminPage");
        if (IsValueUndefined(isAdminPage)) {
            isAdminPage = false;
        } else {
            isAdminPage = isAdminPage === "true" ? true : false;
        }

        let storageItem = sessionStorage.getItem("CMUser");
        let cmUser = null;
        if (storageItem) {
            cmUser = JSON.parse(storageItem);
        }

        this.stripePk = cookies.get('StripePk');

        let displayedLanguage = "";
        if (this.props.history.location.pathname.startsWith("/index.html")) {
            if (this.props.history.location.search !== undefined && this.props.history.location.search.startsWith("?lang=")) {
                let params = this.props.history.location.search.split("=");
                displayedLanguage = params[1];
                cookies.set("lang", displayedLanguage);
            }
        } else {
            let cookieLang = cookies.get("lang");
            if (!IsValueUndefined(cookieLang)) {
                displayedLanguage = cookieLang
            }
        }

        this.state = {
            auth0User: userData,
            CMUser: cmUser,
            error: null,
            bearer: bearer,
            activation: activation,
            apiUrl: apiUrl,
            logoUrl: cookies.get('logoUrl'),
            /* Devextreme popover */
            defaultVisible: false,
            withShadingOptionsVisible: false,
            isAdminPage: isAdminPage,
            isEnvReady: false,
            isLanguageReady: false,
            displayedLanguage: displayedLanguage
        };

        this.test = true;
        this.getUserInfo = this.getUserInfo.bind(this);
        this.getCompany = this.getCompany.bind(this);
        this.changeCurrentPath = this.changeCurrentPath.bind(this);
        this.redisplayUserInfo = this.redisplayUserInfo.bind(this);
        this.getLangStrings = this.getLangStrings.bind(this);

        this.setupRoutes = this.setupRoutes.bind(this);
    }

    async componentDidMount() {
        console.debug("App Loaded");
        let detectBrowserLanguage = window.navigator.userLanguage || window.navigator.language;
        if (this.state.displayedLanguage !== undefined && this.state.displayedLanguage !== "") {
            // TODO - Change this for showing different languages.
            detectBrowserLanguage = this.state.displayedLanguage;
        }

        if (IsRighToLeftLanguage(detectBrowserLanguage)) {
            document.documentElement.setAttribute("lang", detectBrowserLanguage);
            document.documentElement.setAttribute("dir", "rtl");
            document.body.setAttribute("class", "rtl-lang");
        }

        await this.getLangStrings(detectBrowserLanguage);
        await this.getEnvSettings();
        await this.getUserInfo();

        const script = document.createElement("script");

        script.src = "https://js.stripe.com/v3/";
        script.id = "stripe-js";
        script.async = true;

        document.body.appendChild(script);

        if (window.Stripe) {
            console.debug("Stripe found");
            this.setState({
                stripe: window.Stripe(this.stripePk)
            });
        } else {
            console.debug("Stripe not found");
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                console.debug("Stripe loaded");
                this.setState({
                    stripe: window.Stripe(this.stripePk)
                });
            });
        }
    }

    async getLangStrings(lang) {
        console.debug("getLangStrings");
        const response = await fetch("/language/" + lang + ".json");
        let data = {};
        if (response.status === 200) {
            try {
                data = await response.json();
            } catch (ex) {
                console.debug(ex);
                data = {};
            }
        }

        sessionStorage.setItem("languageStrings", JSON.stringify(data));
        this.translator = new StringTranslator();

        this.setState({
            isLanguageReady: true
        })
    }

    async getEnvSettings() {
        console.debug("GetEnvSettings");
        const response = await fetch("/envsettings", {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
        });

        switch (response.status) {
            case 201:
            case 200:
                let data = await response.json();
                if (data !== undefined && data !== null && data.apiUrl !== undefined) {
                    const cookies = new Cookies();
                    cookies.set("ApiUrl", data.apiUrl);
                    cookies.set("StripePk", data.stripePk);
                    this.stripePk = data.stripePk;

                    this.setState({
                        isEnvReady: true
                    });

                }
                break;
            default:
                // do nothing
        }
    }

    changeCurrentPath(newPath) {
        this.setState({
            currentPath: newPath
        })
    }

    showStandaloneActivation() {
        const cookies = new Cookies();
        ClearCookies(cookies);
        cookies.set("Activation", "true");
        this.props.history.push("/login");
        return null;
    }

    getCompany() {
        if (this.state.CMUser !== null) {
            return this.state.CMUser.companyName
        }

        return "";
    }

    handleErrors(response) {
        if (!response.ok) {
            switch (response.status) {
                case 401:
                    throw Error("CM_session_expired");

                default:
                    throw Error(response.statusText)
            }
        }

        return response;
    }

    async getUserInfo() {
        const bearer = this.state.bearer
        const apiUrl = this.state.apiUrl;
        console.debug("GetUserInfo")
        if (this.state.auth0User !== null) {
            const response = await fetch(apiUrl + '/users/' + this.state.auth0User.Email, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': "Bearer " + bearer,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                })
            });

            switch (response.status) {
                case 401:
                    this.setState({
                        CMUser: null,
                        error: "CM_session_expired",
                    })

                    this.props.history.push("/login");
                    break;

                case 200:
                    let data = await response.json();
                    sessionStorage.setItem("CMUser", JSON.stringify(data));
                    this.setState({
                        CMUser: data,
                        error: null,
                        logoUrl: data.companyLogoImageUrl
                    })
                    break;

                default:
                    console.debug("Default");
                    this.setState({
                        CMUser: null,
                        error: response.statusText
                    })
            }
        }
    }

    redisplayUserInfo(name, company) {
        let cmUser = this.state.CMUser;
        if (cmUser) {
            cmUser.fullName = name;
            cmUser.companyName = company;

            this.setState = {
                CMUser: cmUser,
            }
        }
    }

    setupRoutes() {
        if (this.state.isAdminPage) {
            return (
                <Switch>
                    <Route exact path="/" render={(props) => <Main {...this.state} action={this.changeCurrentPath} />} />
                    <Route path="/index.html" render={(props) => <Main {...this.state} action={this.changeCurrentPath} />} />
                    <Route path="/main" render={(props) => <Main {...this.state} history={this.props.history} action={this.changeCurrentPath} />} />
                    <Route path="/admin" render={(props) => <Admin {...this.state} history={this.props.history} />} />
                    <Route component={NoMatch} history={this.props.history} />
                </Switch>
            )
        } else {
            return (
                <Switch>
                    <Route exact path="/" render={(props) => <Main {...this.state} action={this.changeCurrentPath} />} />
                    <Route path="/index.html" render={(props) => <Main {...this.state} action={this.changeCurrentPath} />} />
                    <Route path="/main" render={(props) => <Main {...this.state} history={this.props.history} action={this.changeCurrentPath} />} />
                    <Route path="/firsttime" render={(props) => <Firsttime {...this.state} history={this.props.history} />} />
                    <Route path="/devices" render={(props) => <Devices {...this.state} history={this.props.history} />} />
                    <Route path="/settings" render={(props) => <Settings {...this.state} history={this.props.history} redisplayUserInfo={this.getUserInfo} />} />
                    <Route path="/orders" render={(props) => <Orders {...this.state} history={this.props.history} />} />
                    <Route path="/events" render={(props) => <Events {...this.state} history={this.props.history} />} />
                    <Route path="/licenses" render={(props) => <Licenses {...this.state} history={this.props.history} />} />
                    <Route path="/activate" render={(props) => <Activate {...this.state} history={this.props.history} />} />
                    <Route exact path="/unsubscribe/:id" render={(props) => <Unsubscribe />} />
                    <Route component={NoMatch} />
                </Switch>
            )
        }

    }

    render() {
        if (this.state.isEnvReady && this.state.isLanguageReady) {
            console.debug("Render APP");

            if (this.props.history.location.pathname.startsWith("/unsubscribe")) {
                return (
                    <div className="main-content">
                        <div className="app-body no-menu">
                            <div className="app-header">
                                <div className="app-title">
                                    <span>Crowd <b>Mics</b> Online</span>
                                </div>
                            </div>
                            <div className="app-container">
                                <BrowserRouter>
                                    <Switch>
                                        <Route exact path="/index.html" render={(props) => <Landing history={this.props.history} />} />
                                        <Suspense fallback={<div className='loading'>{this.translator.getString("loading", "Loading...")}</div>}>
                                            <Route exact path="/unsubscribe/:id" render={(props) => <Unsubscribe {...props} />} />
                                        </Suspense>
                                    </Switch>
                                </BrowserRouter>
                            </div>

                            <div className="footer">
                                <div className="col">
                                    <span>&copy; {this.year} Biamp Systems</span>
                                    <span className="divider">|</span>
                                    <span><a rel="noopener noreferrer" href="https://www.biamp.com/legal/privacy-policy" target='_blank'>{this.translator.getString("privacyPolicy", "Privacy Policy")}</a></span>
                                    <span className="divider">|</span>
                                    <span><a rel="noopener noreferrer" href="https://www.biamp.com/legal/terms-of-use" target="_blank">{this.translator.getString("termsOfUse", "Terms of Use")}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            if (IsValueUndefined(this.state.bearer)) {
                return (
                    <div className="App">
                        <BrowserRouter>
                           <Suspense fallback={<div className='loading'>{this.translator.getString("loading", "Loading...")}</div>}>
                               <Switch>
                                    <Route exact path="/" render={(props) => <Landing history={this.props.history} />} />
                                    <Route exact path="/index.html" render={(props) => <Landing history={this.props.history} />} />
                                    <Route exact path="/invite/:id" render={(props) => <Landing {...props} history={this.props.history} />} />
                                    <Route exact path="/activate" render={(props) => <Activate {...this.state} history={this.props.history} />} />
                                    <Route render={(props) => <Landing history={this.props.history} />} />
                                </Switch>
                            </Suspense>
                        </BrowserRouter>
                    </div>
                )
            }

            if (this.state.activation) {
                return (
                    <BrowserRouter>
                        <div className="main-content">
                            <Suspense fallback={<div className='loading'>{this.translator.getString("loading", "Loading...")}</div>}>
                                <Switch>
                                    <Route exact path="/" render={(props) => <Landing history={this.props.history} />} />
                                    <Route path="/activate" render={(props) => <Activate {...this.state} history={this.props.history} />} />
                                    <Route path="/main" render={(props) => <Main {...this.state} history={this.props.history} action={this.changeCurrentPath} />} />
                                </Switch>
                            </Suspense>
                        </div>
                    </BrowserRouter>
                )
            }

            if (this.state.stripe) {
                return (
                    <BrowserRouter>
                        <StripeProvider stripe={this.state.stripe}>
                            <div className="main-content">
                                <Navbar {...this.state} hideNavbar={this.hideNavbar} history={this.props.history} />
                                <div className="app-body">
                                    <div className="app-header">
                                        <div className="app-title">
                                            {/*
                                            <div className="menu-btn">
                                                <div className="ico-menu" onClick={this.toggleNavbar}></div>
                                            </div>
                                            */}
                                            <span>Crowd <b>Mics</b> Online</span>
                                        </div>
                                    </div>

                                    <div className="app-container">
                                        <Suspense fallback={<div className='loading'>{this.translator.getString("loading", "Loading...")}</div>}>
                                            <this.setupRoutes />
                                        </Suspense>
                                    </div>
                                    <div className="footer">
                                        <div className="col">
                                            <span>&copy; {this.year} Biamp Systems</span>
                                            <span className="divider">|</span>
                                            <span><a rel="noopener noreferrer" href="https://www.biamp.com/legal/privacy-policy" target='_blank'>{this.translator.getString("privacyPolicy", "Privacy Policy")}</a></span>
                                            <span className="divider">|</span>
                                            <span><a rel="noopener noreferrer" href="https://www.biamp.com/legal/terms-of-use" target="_blank">{this.translator.getString("termsOfUse", "Terms of Use")}</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StripeProvider>
                    </BrowserRouter>
                );
            } else {
                return null
            }
        } else {
            return null;
        }
    }
}

