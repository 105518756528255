import React from 'react';
import { NavLink } from "react-router-dom";
import UserInfo from './UserInfo';
import StringTranslator from '../LanguageTranslator';

class Navbar extends React.Component {

    constructor(props) {
        super(props);

        console.debug("Initialize Navbar");
        this.state = {
            auth0User: props.auth0User,
            CMUser: props.CMUser,
            error: props.error,
            logoUrl: props.logoUrl
        };

        this.translator = new StringTranslator();
        this.renderMenuItems = this.renderMenuItems.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(props) {
        this.setState({
            auth0User: props.auth0User,
            CMUser: props.CMUser,
            error: props.error,
            logoUrl: props.logoUrl
        });
    }

    renderMenuItems(props) {
        if (props.isAdminPage) {
            return null
        } else {
            let cls = "";
            if (this.state.CMUser !== null && this.state.CMUser.isNew) {
                cls = "disabled";
            } else if (this.state.error !== null) {
                cls = "disabled";
            }

            return (
                <ul className={cls}>
                    <li className={this.getSelected('/devices')} id='devices' onClick={(evt) => this.updatePath(evt)}><NavLink to="/devices" className="link-class"><span className="nav-item">{this.translator.getString("devices", "Devices")}</span></NavLink></li>
                    <li className={this.getSelected('/licenses')} id='licenses' onClick={(evt) => this.updatePath(evt)}><NavLink to="/licenses" className="link-class"><span className="nav-item">{this.translator.getString("licenses", "Licenses")}</span></NavLink></li>
                    <li className={this.getSelected('/orders')} id='orders' onClick={(evt) => this.updatePath(evt)}><NavLink to="/orders" className="link-class"><span className="nav-item">{this.translator.getString("orders", "Orders")}</span></NavLink></li>
                    <li className={this.getSelected('/events')} id='events' onClick={(evt) => this.updatePath(evt)}><NavLink to="/events" className="link-class"><span className="nav-item">{this.translator.getString("events", "Events")}</span></NavLink></li>
                    <li className={this.getSelected('/settings')} id='settings' onClick={(evt) => this.updatePath(evt)}><NavLink to="/settings" className="link-class"><span className="nav-item">{this.translator.getString("settings", "Settings")}</span></NavLink></li>
                </ul>
            )
        }
    }

    getSelected(linkName) {
        let targetPath = document.location.pathname;

        if (this.state.currentPath !== undefined) {
            targetPath = this.state.currentPath
        }

        if (linkName === targetPath) {
            return "selected"
        } else {
            return ""
        }

    }

    updatePath(evt) {
        this.setState({
            currentPath: '/' + evt.currentTarget.getAttribute("id")
        })
    }

    render() {
        console.debug("Render Nav Bar");
        return (
            <div id="navbar" className="nav-bar">
                <UserInfo  {...this.state} isAdminPage={this.props.isAdminPage} history = { this.props.history } />
                <this.renderMenuItems isAdminPage={this.props.isAdminPage} />
            </div>
        )
    }
}

export default Navbar;