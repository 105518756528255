export default class LanguageTranslator {
    constructor() {
        /*
        this.stringValues = {
            "welcome": "へようこそ",
            "login": "ログイン"
        };
        */
        console.debug("LanguageTranslator - constructor");
        this.stringValues = JSON.parse(sessionStorage.getItem("languageStrings"));
     }

    getString(id, defaultStr) {
        //console.debug("getString");
        if (id !== undefined && this.stringValues !== undefined && this.stringValues !== null && this.stringValues[id] !== undefined) {
            return this.stringValues[id];
         }

        return defaultStr === undefined ? "" : defaultStr;
    }

    getStringParam(id, params, defaultStr) {
        console.debug("getStringParam");
        let str = defaultStr !== undefined ? defaultStr : "";
        if (id !== undefined && this.stringValues[id] !== undefined) {
            str = this.stringValues[id].repeat(1);
        }

        for (let i = 0; i < params.length; i++) {
            let find = "{" + params[i].key + "}";
            let re = new RegExp(find, 'g');
            str = str.replace(re, params[i].value);
        }

        return str;
    }
}