import React from 'react';
import { Popover } from 'devextreme-react/popover';
import Cookies from 'universal-cookie';
import { ClearCookies } from '../Utility';
import StringTranslator from '../LanguageTranslator';

import defaultBiampLogo from '../img/logo_biamp.svg'

export default class UserInfo extends React.Component {

    constructor(props) {
        super(props);

        console.debug("Initialize UserInfo");

        this.state = {
            withShadingOptionsVisible: false,
            CMUser: props.CMUser === undefined ? null : props.CMUser,
            auth0User: props.auth0User === undefined ? null : props.auth0User,
            logoUrl: props.logoUrl,
            isUserInfoOpen: false
        }

        this.translator = new StringTranslator();
        this.getUserFullName = this.getUserFullName.bind(this);
        this.showWithShadingOptions = this.showWithShadingOptions.bind(this);
        this.hideWithShadingOptions = this.hideWithShadingOptions.bind(this);
        this.getCompany = this.getCompany.bind(this);
        this.showLogout = this.showLogout.bind(this);
        this.logout = this.logout.bind(this);
        this.showUserInfo = this.showUserInfo.bind(this);
        this.hideUserInfo = this.hideUserInfo.bind(this);
        this.userInfoTemplate = this.userInfoTemplate.bind(this);
    }

    componentWillReceiveProps(props) {
        console.debug("UserInfo: state changed");
        this.setState({
            CMUser: props.CMUser,
            auth0User: props.auth0User === undefined ? null : props.auth0User,
            logoUrl: props.logoUrl
        });
    }

    getUserFullName() {
        if (this.state.CMUser !== null && this.state.CMUser !== undefined) {
            if (this.state.CMUser.isNew === false) {
                return this.state.CMUser.fullName;
            }
        }

        if (this.state.auth0User !== null) {
            return this.state.auth0User.FullName;
        }

        return "";
    }

    showWithShadingOptions() {
        this.setState({
            withShadingOptionsVisible: true
        });
    }

    hideWithShadingOptions() {
        this.setState({
            withShadingOptionsVisible: false
        });
    }

    showUserInfo() {
        this.setState({
            isUserInfoOpen: true
        });
    }

    hideUserInfo() {
        this.setState({
            isUserInfoOpen: false
        });
    }

    getCompany() {
        if (this.state.CMUser !== null && this.state.CMUser !== undefined) {
            return this.state.CMUser.companyName
        }

        return "";
    }

    userInfoTemplate(contentElement) {
        //let element = document.createElement("button");
        //element.innerHTML = "Logout";
        contentElement.classList.add("userinfo-wrapper");
        let htmlStr = "<div class='userinfo-popover'><div class='user'><div class='user-name'>" + this.getUserFullName() + "</div><div class='company-name'>" + this.getCompany() + "</div></div><hr>";
        htmlStr += "<div id='logout-btn' class='logout-btn'>" + this.translator.getString("logout", "LOG OUT") + "</div > ";
        contentElement.innerHTML = htmlStr;
        document.getElementById('logout-btn').onclick = this.logout;
    }

    showLogout(contentElement) {
        let element = document.createElement("button");
        element.innerHTML = "Logout";
        contentElement.innerHTML = "<div id='logout-btn' class='logout-btn'>" + this.translator.getString("logout", "LOG OUT") + "</div>";
        document.getElementById('logout-btn').onclick = this.logout;
    }

    logout() {
        const cookies = new Cookies();
        ClearCookies(cookies);
        this.props.history.push("/index.html");
    }

    render() {
        let icoClass = "ico";
        let imgUrl = this.state.logoUrl;
        if (this.props.isAdminPage) {
            icoClass = "default-ico";
            imgUrl = defaultBiampLogo;

        } else {
            if (this.state.logoUrl && this.state.logoUrl.substring(this.state.logoUrl.lastIndexOf("/")).startsWith("/default_logo")) {
                icoClass = "default-ico";
                imgUrl = defaultBiampLogo;
            }
        }

        return (
            <div className="app-toolbar">
                <div className={icoClass} title="Company Logo"><img src={imgUrl} alt="Company Logo" /></div>
                <div className="user-info">
                    <div className="user-name"><span id="user-name" title={ this.getUserFullName() } onClick={this.showWithShadingOptions}>{this.getUserFullName()}</span></div>
                    <Popover
                        target={'#user-name'}
                        position={'bottom'}
                        width={150}
                        visible={this.state.withShadingOptionsVisible}
                        onHiding={this.hideWithShadingOptions}
                        shading={true}
                        shadingColor={'rgba(0, 0, 0, 0.5)'}
                        contentTemplate={this.showLogout}
                    >

                    </Popover>
                </div>
            </div>
        )
    }
}