
export function DateFormat(dateTimeString) {
    // dateTimeString: 2020-11-13T00:00:00

    if (dateTimeString !== undefined && dateTimeString !== null && dateTimeString !== "") {
        let date = new Date(dateTimeString);
        return date.toLocaleDateString([], {day: "2-digit", month: "2-digit", year:"numeric"})
    }

    return "";
}

export function DateTimeFormat(dateTimeString) {
    // dateTimeString: 2020-11-13T00:00:00
    if (dateTimeString !== undefined && dateTimeString !== null && dateTimeString !== "") {
        let date = new Date(dateTimeString);
        return DateFormat(dateTimeString) + " " + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    return "";
}

export function IsMobileBrowser() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        }

        return false;
}

export function IsValueUndefined(value) {
    if (value === undefined)
        return true;

    if (value === null)
        return true;

    if (value === "null")
        return true;

    return false;
}

export function ClearCookies(cookies) {
    cookies.remove("ApiUrl");
    cookies.remove("Bearer");
    cookies.remove("UserData");
    cookies.remove("AdminPage");
    cookies.remove("joincode");
}

export function GetDefaultLicenseExpirationDate(d) {
    console.debug("Get default expiration date");
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year + 1, month, day);
}

export function IsRighToLeftLanguage(lang) {
    const rtlLanguages = ["ar", "he"];
    return rtlLanguages.indexOf(lang) >= 0;
}

export function TranslateLicenseDescription(translator, value) {
    if (!IsValueUndefined(value)) {
        if (value.startsWith("Float"))
            return translator.getString("floating", "Floating");

        if (value.startsWith("Single")) {
            return translator.getString("singleUse", "Single Use");
        }

        if (value.startsWith("Device")) {
            return translator.getString("device", "Device");
        }
    }
   
    return value;
}