import React from 'react';
import { Redirect } from 'react-router-dom';
import StringTranslator from '../LanguageTranslator'

class Main extends React.Component {

    constructor(props) {
        super(props);

        console.debug("Initialize Main");

        this.state = {
            CMUser: props.CMUser,
            error: null
        };

        this.translator = new StringTranslator();

    }

    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    render() {
        if (this.state.error !== null) {
            if (this.state.error === "CM_session_expired") {
                return <Redirect to='/login' />;
            } else {
                return (
                    <div className="app-containter-msg">{this.state.error}</div>
                );
            }
        }

        if (this.props.isAdminPage) {
            return (<Redirect to='/admin' />);
        } else if (this.props.activation === "true") {
            return (<Redirect to='/activate' />);
        } else {

            if (this.state.CMUser === null) {
                return (
                    <div className="app-containter-msg">{this.translator.getString("loading", "Loading...")}</div>
                );
            }

            if (this.state.CMUser.isNew) {
                this.props.action("/firsttime");
                return <Redirect to='/firsttime' />;
            }

            this.props.action("/devices");
            return <Redirect to='/devices' />;
        }
    }
}

export default Main;