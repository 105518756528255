import React from 'react';
import Cookies from 'universal-cookie';
import { ClearCookies } from '../Utility';
import StringTranslator from '../LanguageTranslator'

export default class Landing extends React.Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        ClearCookies(cookies);
        if (this.props.match !== undefined) {
            cookies.set("joincode", this.props.match.params.id, { path: '/' });
        }

        this.doLogin = this.doLogin.bind(this);
        this.translator = new StringTranslator();
    }

    doLogin() {
        this.props.history.push("/login");
    }

    render() {
        return (
            <React.Fragment>
                <div className="landing-wrapper">
                    <div className="landing-content">
                        <div className='header'>
                            <b>{this.translator.getString("welcome", "Welcome to")} </b>Crowd <b>Mics Online</b>
                        </div>
                        <div>
                            <button className="login-btn" onClick={this.doLogin}>{this.translator.getString("login", "Log in")}</button>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
