import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './main.min.css';
import history from './History';

const rootElement = document.getElementById('root');
    
ReactDOM.render(<App history={history} />, rootElement);

